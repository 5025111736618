<template>
    <div class="chat-all">
        <MHeader title="商家客服" />
        <div class="chat-main m-padding" ref="chatMain">
            <div class="empty"></div>
            <div class="chat-item-box" v-for="(item, index) in chatList" :key="index">
                <!-- 各种消息类型  通过判断类型显示 -->
                <div class="chat-left" v-if="item.type == 1">
                    <div>{{ item.msg }}</div>
                </div>

                <div class="chat-right" v-if="item.type == 2">
                    <div>{{ item.msg }}</div>
                </div>

                <div class="date" v-if="item.type == 3">{{ item.date }}</div>

                <div class="chat-question" v-if="item.type == 4">
                    <p class="question-tip van-hairline--bottom">常见问题</p>
                    <p class="question-dot">
                        <span>活动</span>
                        <span @click="showOrder = true">订单</span>
                        <span>物流</span>
                    </p>
                    <p class="question-item" @click="insertHtml(2, '包邮政策')">包邮政策</p>
                    <p class="question-item" @click="insertHtml(2, '修改订单信息')">修改订单信息</p>
                    <p class="question-item" @click="insertHtml(2, '商品漏发')">商品漏发</p>
                </div>

                <m-divider title="以上为历史消息" class="histore" v-if="item.type == 5"></m-divider>

                <!-- 订单 -->
                <div class="order-chat" v-if="item.type == 6">
                    <div class="item-head m-padding">
                        <div class="date">您的订单交易成功</div>
                    </div>
                    <div class="item-content van-hairline--top van-hairline--bottom" v-for="(it, ind) in 2" :key="ind">
                        <div class="content-left"><img src="@/assets/fork-imgs/156740814737337x3vk.png" alt="goods"></div>
                        <div class="content-right">
                            <p class="goods-title van-ellipsis">迷你萌熊加湿器小型喷雾真是好用谁用谁知道</p>
                            <div class="goods-info">
                                <span class="goods-sku">雾霾蓝-机械版</span>
                                <span class="goods-status">×1</span>
                            </div>
                            <p class="price">$78.93</p>
                        </div>
                        <div class="appliction">申请售后</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="chat-tools" :class="{'tools-active' : isOpenTools}">
            <form action="#">
                <input type="text" class="input-bar" placeholder="说说你的问题吧…" @keydown.enter="sendMsg"
                v-model="inputText">
                <img src="@/assets/message/icon_tianjia@2x.png" alt="" @click="isOpenTools = !isOpenTools">
            </form>
            <!-- 工具框 -->
            <div class="tools-main">
                <div class="tools-item" @click="showOrder = true">
                    <img src="@/assets/message/order.png" alt="">
                    <p>订单</p>
                </div>
                <div class="tools-item">
                    <img src="@/assets/message/photo.png" alt="">
                    <p>照片</p>
                    <label for="photo" class="photoLabel"></label>
                </div>
                <input imgcount = "0" type="file" name="file" accept="image/gif,image/jpeg,image/bmp,image/jpg,image/png"
                id="photo" multiple="multiple">
            </div>
        </div>
        <van-action-sheet v-model="showOrder" title="请选择订单" class="showOrder">
            <van-search v-model="value" placeholder="请输入搜索关键词" :clearable='true' />
            <div class="order-search-main">
                <div class="order-item" v-for="(item, index) in 8" :key="index" @click="insertHtml(6)">
                    <div class="item-head m-padding">
                        <div class="date">2020.6.30</div>
                        <div class="status">待支付</div>
                    </div>
                    <div class="item-content van-hairline--top van-hairline--bottom" v-for="(it, ind) in 2" :key="ind">
                        <div class="content-left"><img src="@/assets/fork-imgs/156740814737337x3vk.png" alt="goods"></div>
                        <div class="content-right">
                            <p class="goods-title van-ellipsis">迷你萌熊加湿器小型喷雾真是好用谁用谁知道</p>
                            <div class="goods-info">
                                <span class="goods-sku">雾霾蓝-机械版</span>
                                <span class="goods-status">×1</span>
                            </div>
                            <p class="price">$78.93</p>
                        </div>
                    </div>
                </div>
            </div>
        </van-action-sheet>
    </div>
</template>

<style lang="less">
.chat-all{
    width:100%;height:100%;
    background-color: #F5F9FF;overflow: hidden;display: flex;flex-direction: column;
    .chat-main{width:100%;margin-top:44px;flex: 1;height:0;overflow-y: auto;padding-bottom:15px;
        .empty{width:100%;height:1px}
        .date{
            margin:15px 0;
            text-align: center;
            font-size:12px;
            
            font-weight:300;
            color:rgba(153,153,153,1);
            line-height:17px;
        }
        .chat-left{
            width:100%;overflow: hidden;
            div{
                float: left;
                margin-top:15px;
                max-width:290px;
                background:rgba(255,255,255,1);
                box-shadow:0px 10px 15px 0px rgba(217,217,217,0.05);
                border-radius:0px 10px 10px 10px;
                padding:16px;
                font-size:14px;
                
                font-weight:400;
                color:rgba(51,51,51,1);
                line-height:20px;
                word-break: break-all;
            }
        }
        .chat-right{
            width:100%;overflow: hidden;
            div{
                float: right;
                max-width:290px;
                margin-top:15px;
                background:rgba(204,224,255,1);
                border-radius:10px 0px 10px 10px;
                box-shadow:0px 10px 15px 0px rgba(217,217,217,0.05);
                padding:16px;
                font-size:14px;
                
                font-weight:400;
                color:rgba(51,51,51,1);
                line-height:20px;
                word-break: break-all;
            }
        }
        .histore{margin:15px 0;
            div{
                font-size: 12px;
                font-weight:400;
                color:rgba(153,153,153,1);
            }
        }
        .chat-question{width:100%;height:187px;background-color: #fff;margin-top:16px;padding:0 16px;
            .question-tip{
                line-height: 40px;
                font-size:14px;
                
                font-weight:400;
                color:rgba(51,51,51,1);
            }
            .question-dot{margin:10px 0;
                span{width:54px;height:20px;border-radius: 20px;border:1px solid rgba(179,179,179,1);text-align: center;line-height: 20px;
                display: inline-block;margin-right:20px;font-size: 12px}
            }
            .question-item{
                font-size:14px;
                
                font-weight:400;
                color:rgba(51,51,51,1);
                line-height: 30px;
            }
        }
    }
    .chat-tools{width:100%;background-color: #fff;border-top:1px solid #f7f7f7;
        .input-bar{
            margin:10px 0 0 16px;
            width:296px;
            height:40px;
            background:rgba(255,255,255,1);
            border-radius:20px;
            border:1px solid rgba(51,131,255,1);
            font-size:14px;
            
            font-weight:400;
            color:rgba(153,153,153,1);
            line-height:20px;
            padding:0 15px;
            display: inline-block;
        }
        form>img{
            width:30px;
            height:30px;
            margin:15px 0 0 17px;
            display: inline-block;
            vertical-align: -10px;
            transition: transform 0.5s;
        }
        .tools-main{width:100%;height:0px;margin-top:10px;border-top:1px solid #f1f1f1;overflow: hidden;
            transition: height 0.5s;display: flex;flex-wrap: wrap;padding:0 16px;box-sizing: border-box;
            .tools-item{
                position: relative;
                margin-top:10px;
                margin-right:20px;
                width:40px;
                height:70px;
                img{margin:6px;width:28px;height:28px}
                p{
                    text-align: center;font-size:12px;font-weight:400;
                    color:rgba(102,102,102,1);
                }
            }
            #photo{display: none}
            .photoLabel{position: absolute;top:0;left:0;z-index: 99;width:100%;height:100%;}
        }
    }
    .tools-active{
        .tools-main{height:90px;}
        form>img{transform: rotate(45deg)}
    }

    .showOrder{height:446px;border-radius:10px 10px 0px 0px;
        .order-search-main{width:100%;height:348px;overflow-y: auto;}
    }
    .order-item,.order-chat {
        margin-top: 10px;
        background-color: #fff;
        box-shadow: 0px 10px 0px 0 #F5F9FF;
        .item-head {
            width: 100%;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .status{
                font-size:14px;
                font-weight:400;
                color:rgba(102,102,102,1);
            }
            .date {
                font-size:14px;
                font-weight:400;
                color:rgba(102,102,102,1);
            }
        }
        .item-content {
            margin-left: 16px;
            height: 103px;
            display: flex;
            align-items: center;
            .content-left {
                width: 80px;
                height: 80px;
                margin-right: 12px;
                border-radius: 5px;
                &>img {
                    width: 80px;
                    height: 80px;
                    border-radius: 4px;
                }
            }
            .content-right {
                flex: 1;
                height: 80px;
                .goods-title{
                    height: 25px;
                    line-height: 25px;
                    padding-right: 50px;
                    width: 245px;
                    font-size:16px;
                    font-weight:400;
                    color:rgba(51,51,51,1);
                }
                .goods-info {
                    width: 100%;
                    padding-right: 16px;
                    display: flex;
                    align-content: center;
                    justify-content: space-between;
                    margin-bottom: 6px;
                    .goods-sku {
                        height: auto;
                        display: inline-block;
                        border-radius:3px;
                        font-size:12px;
                        font-weight:400;
                        color:rgba(102,102,102,1);
                        height: 26px;
                        line-height: 26px;
                    }
                    .goods-status{
                        font-size:12px;
                        font-weight:400;
                        color:rgba(102,102,102,1);
                    }
                }
                .price{
                    font-size:14px;
                    
                    font-weight:500;
                    color:rgba(51,51,51,1);
                }
            }
        }
    }
    .order-chat{
        border-radius: 4px;
        box-shadow:none;
        overflow: hidden;
        .goods-status{margin-right:10px}
        .item-content{position: relative;padding-bottom:30px;height:auto;padding:15px 0 30px 0}
        .appliction{
            margin:0 15px 15px 0;
            position: absolute;
            right:15px;
            bottom:0px;
            width:94px;
            height:24px;
            background:linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%);
            border-radius:12px;
            font-size:12px;
            
            font-weight:400;
            color:rgba(255,255,255,1);
            line-height: 24px;
            text-align: center
        }
    }
}
</style>

<style lang="less">
.chat-all{
    .van-action-sheet__header{border-bottom:1px solid #f1f1f1}
    .van-search__content{border-radius: 54px}
}
</style>

<script>
import MHeader from '@/components/en/m-header.vue'
import MDivider from '@/components/en/m-divider.vue'
export default {
    components: { MHeader, MDivider },
    data() {
        return {
            isOpenTools: false,
            inputText: '',
            value:'',
            chatList: [
                {type: 1, msg: '左侧消息'},
                {type: 2, msg: '右侧消息'},
                {type: 2, msg: '右侧消息'},
                {type: 2, msg: '右侧消息'},
                {type: 3, date: '17:29'},
                {type: 4},
                {type: 5},
                {type: 6}
            ],
            showOrder: false
        }
    },
    methods: {
        insertHtml(flag, data) {
            // 插入消息this.chatList.push({type: flag, msg: data})
            switch(flag) {
                case 1 : this.chatList.push({type: 1, msg:  data}); break;
                case 2 : this.chatList.push({type: 2, msg:  data}); break;
                case 3 : this.chatList.push({type: 3, date: data}); break;
                case 4 : this.chatList.push({type: 4});             break;
                case 5 : this.chatList.push({type: 5});             break;
                case 6 :
                    this.chatList.push({type: 6});
                    this.showOrder = false;
                break;
            }
            // 滚动到底部
            setTimeout(() => {
                this.$refs.chatMain.scrollTop = this.$refs.chatMain.scrollHeight - this.$refs.chatMain.clientHeight
            }, 10)
        },
        sendMsg(e) {
            // 发送消息
            e.preventDefault();
            e.stopPropagation();
            if(this.inputText == '') {
                this.$toast('请输入内容后发送')
                return false
            }
            this.insertHtml(1, this.inputText)
            this.insertHtml(2, this.inputText)
            this.inputText = ""
        }
    },
    mounted() {
        // 消息挂载完成时  滚动到底部
        this.$refs.chatMain.scrollTop = this.$refs.chatMain.scrollHeight - this.$refs.chatMain.clientHeight
    },
    watch: {
        isOpenTools(val) {
            if(val && this.$refs.chatMain.scrollTop == (this.$refs.chatMain.scrollHeight - this.$refs.chatMain.clientHeight)) {
                let time = 0;
                let toolsTimer = setInterval(() => {
                    time++;
                    if(time >= 200) {
                        clearInterval(toolsTimer)
                    }
                    this.$refs.chatMain.scrollTop += 2;
                }, 1)
            }
        }
    }
}
</script>